import React, { useEffect, useState } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import AxiosWrapper from "../../../../services/axios";
import AudioEyeSupport from "../../../../services/audioEyeSupport";
import SubmitApplicationService from "../../../../services/submitApplication";


function MfaSms() {

    const Setting = useStoreState((state) => state.siteSettingsModel);
    const setApplicationResult = useStoreActions(actions => actions.applicationResultModel.setApplicationResult);
    const SubmitApp = SubmitApplicationService()
    const ada = new AudioEyeSupport();
    const info = useStoreState(state => state.applicantModel.applicant);
    const mfaActive = useStoreState((state) => state.mfaSms);
    const setMfaSms = useStoreActions(actions => actions.setMfaSms);
    const setMfaEmail = useStoreActions(actions => actions.setMfaEmail);
    const setDeadEndMfaSms = useStoreActions(actions => actions.setDeadEndMfaSms);
    const history = useHistory();
    const [code, setCode] = useState('')
    const [showExpired, setShowExpired] = useState(false)
    const [showOptions, setShowOptions] = useState(true)
    const [errorText, setErrorText] = useState(false)
    const setdisplayResult = useStoreActions((actions) => actions.setDisplayResult);
    const setSessionTimeoutId = useStoreActions((actions) => actions.setSessionTimeoutId);
    const client = new AxiosWrapper()
    const [loading, setLoading] = useState(false)
    const ApplicantResult = useStoreState(state => state.applicationResultModel.applicationResult);
    const [showResend, setShowResend] = useState(false);
    const sessionTimeoutId = useStoreState(state => state.sessionTimeoutId);
     // eslint-disable-next-line no-unused-vars
    const [timeRemaining, setTimeRemaining] = useState(process.env.REACT_APP_MFA_SESSION_TIME); // 600 seconds = 10 minutes
    const [timerId, setTimerId] = useState(null);
    const fields = Array(6).fill("")

    useEffect(() => {
        if(mfaActive === true){
            const id = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime > 0) {
                    return prevTime - 1;
                    
                } else {
                    clearInterval(id);
                    setMfaSms(false) 
                    setMfaEmail(false)
                    setDeadEndMfaSms(true)
                return 0;
                }
            });
            }, 1000);
            setSessionTimeoutId(id)
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [mfaActive]);

    useEffect(() => {
        if(mfaActive === true){
            handleInputBlock('block1');
            Object.defineProperty(window, 'bridge', {
                set: (value) => {
                    setCode(value);
                },
                configurable: true,
              });
        }
        

    },[mfaActive])


    function getPhoneMasked(){
        return '(***)-***-' + info.PrimaryPhone.slice(-4);
    }

    function reSendSms(){
        sendSmsCode('Text')
        setShowOptions(false)
        
    }

    function stopTimer(){
        if (timerId) {
          clearInterval(timerId); // Clear the interval
          setTimerId(null); // Reset the timer ID
        }
    }

  

    async function Submit(){
        setLoading(true)
        setShowOptions(false)
        let result = await client.post('/verify-Mfa', {verifyCode : code, ApplicantId : ApplicantResult.ref_number, WebUrl : Setting.domain}, true);

        if(result && result.Success === true){
            sessionStorage.setItem('sessionToken', result.Payload)
            SubmitApplication()
            return
        }
        if(result && result.Success === false ){
            if(result.StatusCode === 401){
                if(result.Error === 'Expired'){
                    
                    setLoading(false)
                    sessionStorage.setItem('sessionToken', result.Payload)
                    setErrorText('The verification code you entered is invalid, or your code has expired.')
                    setShowExpired(true)
                    setCode('')
                    document.querySelectorAll(`#block1 .mfaInputs`)[0].focus()
                    return
                }
                if(result.Error === 'NoMatch'){
                    setLoading(false)
                    sessionStorage.setItem('sessionToken', result.Payload)
                    setErrorText('The verification code you entered is invalid, or your code has expired.')
                    setCode('')
                    document.querySelectorAll(`#block1 .mfaInputs`)[0].focus()
                    return
                }
                if(result.Error === 'TooManyAttempts'){
                    setLoading(false)
                    sessionStorage.setItem('sessionToken', result.Payload)
                   
                    setApplicationResult({result : 'MFATOOMANYATTEMPTS'});
                    setdisplayResult(true)
                    window.scrollTo(0, 0);
                    ada.sendDYEvent({name : 'code_failure'});
                    history.push(`/declined`+window.location.search) 
                   
                    return
                }
               
            }
           
        }
        if(result === false){
            SubmitApplication()
            return
        }
        setLoading(false)
        setErrorText('An Error has occurred. Please try again later.')
    }

    async function SubmitApplication(){
        
            try{
                stopTimer()
                ada.sendDYEvent({name : 'code_success'});
                let result = await SubmitApp.submitApplicationOffer(info);
                setApplicationResult(result);
                window.scrollTo(0, 0);
                if(result){
                    clearInterval(sessionTimeoutId)
                    setdisplayResult(true)
                    if(result.decision === "APPROVED"){
                    setApplicationResult({ result: "APPROVED" });
                    history.push(`/approved`+window.location.search);
                    return true;
                    }
                    history.push(`/declined`+window.location.search);
                    return true;
                }
                setErrorText('An Error has occurred. Please try again later.')
                return false;
            }catch(Error){
                console.log(Error);
                setErrorText('An Error has occurred. Please try again later.')
            }
         
    }

    async function changeToEmail(){
        setMfaEmail(true)
        setMfaSms(false)
    }

    async function sendSmsCode(type){
        let result = await client.post('/send-Mfa', {contactIdentifier : info.PrimaryPhone, contactType : type, ApplicantId : ApplicantResult.ref_number, WebUrl : Setting.domain}, true)
        if(result && result.Success === true){
            sessionStorage.setItem('sessionToken', result.Payload)
        
            return
        }
        setErrorText('An Error has occurred. Please try again later.')
    }

    function handleInputBlock(blockId) {
        let inputs = document.querySelectorAll(`#${blockId} .mfaInputs`);
    
        inputs.forEach((input, index, array) => {

            if (index === 0) {
                input.addEventListener('paste', (e) => {
                    // Get pasted data
                    const pasteData = (e.clipboardData || window.clipboardData).getData('text');
    
                    // Validate if it's 6 digits
                    if (/^\d{6}$/.test(pasteData)) {
                        // Split the string and assign each digit to the inputs
                        const digits = pasteData.split('');
                        digits.forEach((digit, i) => {
                            array[i].value = digit;

                        });
                        window.bridge = pasteData
                        array[5].focus(); // Set focus to the last input after pasting
                    }
    
                    e.preventDefault(); // Prevent the default paste behavior
                });
            }
        });
    
        // Initially set focus to the first empty input
        const firstEmptyIndex = [...inputs].findIndex(input => input.value === '');
        if (firstEmptyIndex !== -1) {
            inputs[firstEmptyIndex].focus();
        }
    }

    function setFocusOnBackward(index){
        let inputs = document.querySelectorAll(`#block1 .mfaInputs`);
        let flag = index -1 < 0 ? 0 : index
        flag = flag > 5 ? 5 : flag
        inputs[flag].focus();
    }


    return (
        <>
        <Modal show={mfaActive} backdrop="static" id="mfa-modal" onHide={() => true} centered >
            <Modal.Header>
            <Modal.Title className="justify-content-center align-items-center MfaTitle " >We've {showResend ? 're-sent' : 'sent'} your verification code.</Modal.Title>
            </Modal.Header>
            <Modal.Body  className="font18">
                <div className="row">
                    <div className="col-12">
                        <p className="mb-1">A 6-digit verification code was {showResend ? 're-sent' : 'sent'} to <strong>{ getPhoneMasked() }</strong> </p>
                        <p>Please allow up to 1 minute to receive it.</p>
                        <p className="mb-0"><strong>Enter verification code</strong></p>
                    </div>
                    <div className="col-12">
                        {
                            (errorText !== false) && (
                                <p style={{fontSize : '1.2em'}} className="mb-0 mt-3 text-center error-message"><strong>{ errorText }</strong></p>
                            )
                        } 
                    </div>
                    <div className="col-12">
                        <div className="input-container-modal"  id="block1">
                            {   
                                fields.map((value, index) => (
                                    <input value={code[index] || ''} maxLength={1} 
                                    disabled={loading}
                                    onKeyDown={(e) => {
                                        if(e.nativeEvent.code === "Backspace" || e.nativeEvent.keyCode === 8){
                                            let temp = code.slice(0, -1)
                                            
                                            setCode(code.slice(0, -1))
                                            setFocusOnBackward(temp.length)
                                        }
                                    }}
                                    onInput={(e) => {
                                        let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value : e.nativeEvent.data;
                                        if (/[0-9]/.test(value)) {
                                            if(/^[0-9]+$/.test(value) || value === ""){
                                                if(code.length < 6){
                                                    setCode(code + value)
                                                    let temp = code + value;
                                                    setFocusOnBackward(temp.length)
                                                }
                                            }
                                        } 
                                      
                                    }} type="text" class=" mfaInputs"  />
                                ))
                            }
                        </div>
                   </div>
                   
                    
                </div>
                <div className="row mt-2 MfaSmallerText">
                    <div className="col-12 text-center">
                        <p className="mb-1">By selecting Submit, I authorize a one-time<br />SMS message to verify my device.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        <button onClick={() => Submit()} disabled={code.length < 6 || loading} style={{marginTop : 'none !important'}} className={((code.length < 6 ) ? 'mfaDisabled' : '') + " btn text-small mt-3 MfaEmailButton mfabutton mt-0 btn-info"}> 
                         {!loading ? 'Submit' : 'Submitting...'} 
                        </button>                    
                    </div>
                </div>
                <div className="row mt-2 mb-1 MfaSmallerText">
                    <div className="col-12 text-center">
                        {
                            <>
                          
                            { showExpired === true && (
                                <p className="mb-1"><span className="fakeLink" onClick={() => {reSendSms(); setShowExpired(false); setShowResend(true)}}>Resend code</span></p>
                            )}       
                            { showOptions === true && (
                                <>
                                    <p className="mb-1"><span className="fakeLink" onClick={() => {reSendSms(); setShowResend(true)}}>Didn't get a verification code?</span></p>
                                    <p><span className="fakeLink" onClick={() => changeToEmail()} >Don't have a mobile phone?</span></p>
                                         
                                </>
                            )}
                            </>
                        }
                        
                        
                    </div>
                </div>
                <div className="row MfaSmallerText">
                    <div className="col-12 text-center">
                        <p>Standard message & data rates may apply</p>
                    </div>
                </div>
            </Modal.Body>
          
        </Modal>
        </>
    );
}

export default MfaSms;
