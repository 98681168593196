let GbText = {
    "tooltip" : {
        "upTo" : "Cover Me Now Overdraft Privilege helps manage your finances. Establishing a qualifying direct deposit earns you $50 of coverage and maintaining a $300 average YTD collected account balance earns you $100.",
        "checkingBundle" : "Your Grand360 account automatically includes a checking account and a separate account to help you save and get where you want to go! All the tools you need to organize your finances, budget, and save easily.",
        "easyAccess" : "Grand360 includes online and mobile banking, bill pay, mobile deposit, Zelle®, external transfers, 40,000 surcharge-free MoneyPass® ATMs, spend tracker, and e-statements. These tools require setup. Your mobile carrier determines access and message/data rates.",
        "paidEarly" : "Qualified direct deposits of payroll, pension, and government benefits can provide early payday! Access requires our receipt of funds and isn’t guaranteed. Deposits may vary, risk screenings and transactional limits apply.",
        "morePerk" : "With your Grand.bank Mastercard debit card you get even more benefits, such as: Zero Liability, ID Theft Protection, Extended Warranty, Emergency Cash Advance, and Satisfaction Guarantee services.",
        "moneyBack" : "If during the 1st six full-calendar months you feel the Grand360 plan has not improved your financial life simply close your account and we’ll return the monthly plan fees charged since you opened the plan, provided all accounts are in good standing and not otherwise overdrawn.",
        "automaticSaving" : "Saving can be easy with our automatic savings tools, such as Round-Up Savings and Recurring Transfers, plus you earn interest on your savings balances. It’s time to make your money work for YOU!",
        "jointAccount" : "Want to add a spouse, friend, or family member? Grand360 can do that!",
        "freeMonitoring" : "The credit score monitoring tool allows you to check your credit score anytime right from your phone or computer. It is available 24/7.",
        "wellnessTool" : "You'll have unlimited access to online, interactive financial education to help guide you to reach your goals. Best of all – you can access all the programs anywhere, anytime on our website: grand.bank.",
        "safeSound" : "Deposits with Grand Bank for Savings, FSB, are FDIC-insured up to $250,000 per account, plus you can set up real-time alerts and receive fraud alerts from us.",
        "noCheck" : "A credit check is not required to open a Grand360 account.",
        "creditbuilder" : "Build your credit and build savings at the same time! Elevate your credit score by making on-time loan payments, all of which are reported to the three major credit bureaus. The proceeds for your G360 Credit Builder Loan are held for you by the Bank.  However, unlike other credit builder programs, we don’t keep your loan funds locked in a Certificate of Deposit for the full term.  After you make two on-time loan payments, a portion of each of your future payments is released and made available in your Credit Builder Savings Account.  This makes saving effortless! Access to G360 Credit Builder Loan requires an active    Grand360 Money and Savings Bundle. All loans are subject to credit approval, interest, and fees.",
        "score60": `Boost Your Score Up To 60+: Based on the July 2020 study by CFPB. However, credit score improvement is not guaranteed, as other factors may affect results. All loans are subject to credit approval, interest, and fees. For details visit: <a href="https://www.consumerfinance.gov/about-us/newsroom/cfpb-study-shows-financial-product-could-help-consumers-build-credit/" target="_blank" rel="noopener noreferrer">CFPB Study</a>`,
        "onTimePayments" : "On-time payments are reported to the three major credit bureaus, Experian, Equifax, and Transunion. This builds payment history, which makes up 35% of your credit score. Negative payment history is also reported.",
        "cashBack" : "Loan funds are held securely in an FDIC-insured savings account. You will not receive them upfront. But unlike typical programs, after 60 days and two on-time payments, you’ll receive a portion of each payment back monthly with just a small deduction for interest and fees. This helps build savings!"
    }
}

export default GbText;