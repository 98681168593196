import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import {masking, unmasking, IncomeMask} from '../../../services/masking'
import {  useFormContext } from "react-hook-form";
import IncomeCalculator from "./incomeCalculator";
import moment from "moment";
import SpouseForm from "../add-ons/SpouseForm";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Parser from 'react-html-parser';
import marketing from "../../../services/marketing";
import AxiosWrapper from "../../../services/axios";
import AudioEyeSupport from "../../../services/audioEyeSupport";
import AdobeScript from "../../../services/adobeScript";

function UserDataFormVertical(props) {

    const Setting = useStoreState((state) => state.siteSettingsModel);
    const formFields = useStoreState(state => state.siteSettingsModel.formFields);
    const info = useStoreState((state) => state.applicantModel.applicant);
    const setInfo = useStoreActions(actions => actions.applicantModel.setApplicant);
    const text = useStoreState((state) => state.dmText.terms);
    const [cursor, setCursorPosition] = useState(1)
    const {  register, formState: { errors }, unregister, clearErrors } = useFormContext();
    let disableField = props.disabledFields;
    const utms = new marketing();
    const axios = new AxiosWrapper();
    const ada = new AudioEyeSupport()
    const adobe = new AdobeScript();
    const [loaded, setLoaded] = useState(false)
    
    const getFieldData = (fieldName) => formFields.find(field => field.fieldName === fieldName);
    const checkIfFieldIsActive = (fieldName) => getFieldData(fieldName)?.isActive;

    useEffect(() => {
        if(!loaded){
            
            setInfo({index : "FirstName",   value : axios.findParam("firstname") || info.FirstName})
            setInfo({index : "FirstName",   value : Setting.prefillData['fname'] || info.FirstName})
            setInfo({index : "MiddleName",  value : axios.findParam("middleinitial") || info.MiddleName})
            setInfo({index : "MiddleName",  value : Setting.prefillData['mname'] || info.MiddleName})
            setInfo({index : "Suffix",      value : (axios.findParam("suffix")) ? axios.findParam("suffix").toUpperCase() : info.Suffix})
            setInfo({index : "LastName",    value : axios.findParam("lastname") || info.LastName})
            setInfo({index : "LastName",    value : Setting.prefillData['lname'] || info.LastName})
            setInfo({index : "Address1",    value : axios.findParam("address1") || info.Address1})
            setInfo({index : "Address1",    value : Setting.prefillData['address1'] || info.Address1})
            setInfo({index : "Address2",    value : axios.findParam("address2") || info.Address2})
            setInfo({index : "Address2",    value : Setting.prefillData['address2'] || info.Address2})
            setInfo({index : "State",       value : axios.findParam("state") || info.State})
            setInfo({index : "State",    value : Setting.prefillData['state'] || info.State})
            setInfo({index : "City",        value : axios.findParam("city") || info.City})
            setInfo({index : "City",    value : Setting.prefillData['city'] || info.City})
            setInfo({index : "Email",       value : axios.findParam("email") || info.Email})
            setInfo({index : "Email",    value : Setting.prefillData['email'] || info.Email})
            setInfo({index : "Zip",         value : axios.findParam("zip") || info.Zip})
            setInfo({index : "Zip",    value : Setting.prefillData['zipcode'] || info.Zip})
            setInfo({index : "PrimaryPhone",         value : axios.findParam("phone") || info.PrimaryPhone})
            setInfo({index : "PrimaryPhone",    value : Setting.prefillData['phone'] || info.PrimaryPhone})

            if( axios.findParam("hascheckingaccount")) {
                setInfo({index : "HasCheckingAccount", value : "true"})
            }
            setLoaded(true)
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        //unregister controls to avoid validations
        if (info.hasAdditionalCardholder==="false") {
            unregister(["additionalCardholderFirstName", "additionalCardholderMI", "additionalCardholderLastName"]);
        }
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [info.hasAdditionalCardholder]);

    const clearMonthlyIncomeError = () => {
        clearErrors("MonthlyIncome");
    };

    return (
        <div className="col-12 p-0">   
            <div className="row">
                { /* First Name */}
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="FirstName">First Name</label>
                        <div className="input-container">
                            <input 
                            maxLength={17} 
                            readOnly={Setting.mainSettings.Flow === "DM"}
                            onFocus={() => {ada.sendDYEvent({ name : 'FirstNameFocusEvent'}); adobe.event('form_start')}} 
                            value={info.FirstName} 
                            onInput={(e) => {
                                
                                if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                                    if(/^[a-zA-Z- ]+$/.test(e.target.value) || e.target.value === ""){
                                        if((e.target.value.split(" ").length - 1) < 2){
                                            setInfo({ index: "FirstName", value: e.target.value.trimLeft() })}
                                        }
                                    }
                                }
                            } 
                            type="text" className="form-control" placeholder="First Name" {...register("FirstName")} />
                        </div>
                        {errors.FirstName ? 
                            <div className="error-message"> Required field. Please enter only letters and hyphens. </div>
                            :
                            <></>
                        }
                    </div>
                </div>
                { /* Middle Initial */}
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="MiddleName">MI</label>
                        <div className="input-container">
                            <input 
                            maxLength={1} 
                            value={info.MiddleName} 
                            onInput={(e) => {
                                const inputValue = e.target.value.replace(/[^a-zA-Z]/g, ''); 
                                if (inputValue !== e.target.value || e.nativeEvent.inputType === "deleteContentBackward") {
                                    e.target.value = inputValue; 
                                }
                                setInfo({ index: "MiddleName", value: inputValue });
                            }} 
                            type="text" className="form-control" placeholder="MI" {...register("MiddleName")} />
                        </div>
                        {errors.MiddleName ? 
                            <div className="error-message"> Only letters are allowed </div>
                            :
                            <></>
                        }
                    </div>
                </div>
                { /* Last Name */}
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="LastName">Last Name</label>
                        <div className="input-container">
                            <input 
                                maxLength={25}
                                readOnly={Setting.mainSettings.Flow === "DM"} 
                                value={info.LastName} 
                                onInput={(e) => {
                                    if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                                        if(/^[a-zA-Z- ]+$/.test(e.target.value) || e.target.value === ""){
                                            if((e.target.value.split(" ").length - 1) < 2){
                                                setInfo({ index: "LastName", value: e.target.value.trimLeft() })}
                                            }
                                        }
                                    }
                                } 
                                type="text" className="form-control" placeholder="Last Name" {...register("LastName")} />
                        </div>
                        {errors.LastName ? 
                            <div className="error-message"> Required field. Please enter only letters and hyphens. </div>
                            :
                            <></>
                        }
                    </div>
                </div>
                { /* Suffix */}
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="Suffix">Suffix</label>
                        <div className="input-container">
                            <select
                            className="form-control"
                            id="addtl-ch-suffix"
                            onInput={(e) =>
                                setInfo({ index: "Suffix", value: e.target.value })
                            }
                            {...register("Suffix")}
                            >
                            <option value=""></option>
                            <option value="JR">JR</option>
                            <option value="SR">SR</option>
                            <option value="II">II</option>
                            <option value="III">III</option>
                            <option value="IV">IV</option>
                            </select>
                        </div>
                        {errors.Suffix && errors.Suffix.message.map((message) => (
                            <div className="error-message" key={"Suffix-" + message}> {message} </div>
                        ))}
                    </div>
                </div>
                { /* Home Address */}
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="Address1">Home Address <span>(no P.O. boxes)</span></label>
                        <div className="input-container">
                            <input 
                                maxLength={26}
                                value={info.Address1} 
                                onInput={(e) => {
                                    setInfo({ index: "Address1", value: e.target.value.trimLeft().replace("  ", " ") })}
                                } 
                                type="text" className="form-control" placeholder="Address Line 1" {...register("Address1")} />
                        </div>
                        {errors.Address1 && errors.Address1.message.map((message, i) => (
                            <div className="error-message" key={"Address1-" + i}> {message} </div>
                        ))}
                    </div>
                </div>
                { /* Home Address 2 */}
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="Address2">Address Line 2</label>
                        <div className="input-container">
                            <input 
                                maxLength={26}
                                value={info.Address2} 
                                onInput={(e) => {
                                    setInfo({ index: "Address2", value: e.target.value.trimLeft().replace("  ", " ") })}
                                } 
                                type="text" className="form-control" placeholder="Address Line 2" {...register("Address2")} />
                        </div>
                        {errors.Address2 && errors.Address2.message.map((message, i) => (
                            <div className="error-message" key={"Address2-" + i}> {message} </div>
                        ))}
                    </div>
                </div>
                { /* City */}
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="City">City</label>
                        <div className="input-container">
                            <input 
                                maxLength={18}
                                value={info.City} 
                                onInput={(e) =>{ 
                                    if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                                        if(/^[a-zA-Z ]+$/.test(e.target.value) || e.target.value === ""){
                                            setInfo({ index: "City", value: e.target.value })}
                                        }
                                    }
                                } 
                                type="text" className="form-control" placeholder="City" {...register("City")} />
                        </div>
                        {errors.City && errors.City.message.map((message, i) => (
                            <div className="error-message" key={"City-" + i} > {message} </div>
                        ))}
                    </div>
                </div>
                { /* State */}
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="State">State</label>
                        <div className="input-container">
                            <select defaultValue={axios.findParam("state") || (Setting.prefillData['state'] || info.State)} className="form-control" id="State" onInput={(e) => setInfo({ index: "State", value: e.target.value })} {...register("State")}>
                                <option value=""></option>
                                {Setting.statesList.map((state) => {
                                    return (
                                        <option value={state.Code} key={state.Code}>
                                        {state.Name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        {errors.State && errors.State.message.map((message, i) => (
                            <div className="error-message" key={"State-" + i}> {message} </div>
                        ))}
                    </div>
                </div>
                { /* Zip Code */}
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="Zip">Zip Code</label>
                        <div className="input-container">
                            <input 
                                maxLength={5}
                                value={info.Zip} 
                                onInput={(e) => {
                                    
                                    let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value : e.nativeEvent.data;
                                    if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                                        if(/^[0-9]+$/.test(e.target.value) || e.target.value === ""){
                                            setInfo({ index: "Zip", value: e.target.value })}
                                        }
                                    }
                                }
                                
                                type="text" className="form-control" placeholder="Zip Code" {...register("Zip")} 
                            />
                        </div>
                        {errors.Zip && (
                            <div className="error-message">{errors.Zip.message}</div>
                        )}
                    </div>
                </div>
                { /* Email Address */}
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="Email">Email Address</label>
                        <div className="input-container">
                            <input 
                                maxLength={100}
                                value={info.Email} 
                                onInput={(e) => {
                                    if (/[a-zA-Z0-9_\\.@]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward") {
                                        if (/^[a-zA-Z0-9_\\.@]+$/.test(e.target.value) || e.target.value === ""){
                                            if((e.target.value.split("@").length - 1) < 2 ){
                                                setInfo({ index: "Email", value: e.target.value})
                                            }
                                        }
                                    }
                                }} 
                                onPaste={(e) => {
                                    if (/[a-zA-Z0-9_\\.@]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward") {
                                        if (/^[a-zA-Z0-9_\\.@]+$/.test(e.target.value) || e.target.value === ""){
                                            if((e.target.value.split("@").length - 1) < 2 ){
                                                setInfo({ index: "Email", value: e.target.value})
                                            }
                                        }
                                    }
                                }}
                                type="text" className="form-control" placeholder="" {...register("Email")} />
                        </div>
                        {errors.Email && (
                            <div className="error-message" > {errors.Email.message} </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="ConfirmEmail">Confirm Email Address</label>
                        <div className="input-container">
                            <input 
                                maxLength={100}
                                value={info.ConfirmEmail} 
                                onFocus={() => {ada.sendDYEvent({ name : 'ConfirmEmailFocusEvent'});}} 
                                onInput={(e) => {
                                    if (/[a-zA-Z0-9_\\.@]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward") {
                                        if (/^[a-zA-Z0-9_\\.@]+$/.test(e.target.value) || e.target.value === ""){
                                            if((e.target.value.split("@").length - 1) < 2){
                                                setInfo({ index: "ConfirmEmail", value: e.target.value})
                                            }
                                        }
                                    }
                                }} 
                                onPaste={(e) => e.preventDefault()} 
                                type="text" className="form-control" placeholder="" role="presentation"
                                autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                                {...register("ConfirmEmail")} 
                            />
                        </div>
                        {errors.ConfirmEmail && (
                            <div className="error-message"> {errors.ConfirmEmail.message} </div>
                        )}
                    </div>
                </div>
                { /* SSN Number */}
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="ssn">Social Security Number</label>
                        <div className="input-container">
                            <input 
                                readOnly={Setting.mainSettings.Flow === "DM"} 
                                value={masking(info.SSN, "XXX-XX-XXXX")} 
                                onInput={(e) => {
                                    let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("-","") : e.nativeEvent.data;
                                    if (/[0-9]/.test(value)  || e.nativeEvent.inputType === "deleteContentBackward") {
                                        setInfo({ index: "SSN", value:  unmasking(e,"XXX-XX-XXXX", info.SSN) })}
                                    }   
                                } 
                                type="text" className="form-control" placeholder="xxx-xx-xxxx" {...register("SSN")} />
                        </div>
                        {errors.SSN && (
                            <div className="error-message"> This field is missing or invalid. Please fill it correctly. </div>
                        )}
                    </div>
                </div>
                { /* Monthly Income */}
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="MonthlyIncome">Your Total Monthly Income <span className="text-danger">{info.MonthlyIncome >= 12000 ? "Please Ensure that total Monthly Income is Correct!" : ""}</span></label>
                        <div className="input-container">
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip className="mytooltip"  id="monthly-tooltip" >{text.block1.tooltipIncome}</Tooltip>}>
                                <input 
                                    value={IncomeMask(info.MonthlyIncome)} 
                                    onFocus={() => {ada.sendDYEvent({ name : 'MonthlyIncomeFocusEvent'})}}
                                    onInput={(e) => {
                                        e.preventDefault()
                                        
                                        setCursorPosition(e.target.selectionStart)
                                        
                                        let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("$","").replaceAll(",","") : e.nativeEvent.data;
                                        
                                        if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                                            if(e.target.value.replaceAll("$","").replaceAll(",","") <= 99999){
                                                let value = e.target.value.replaceAll("$","").replaceAll(",","");
                                                value = (!isNaN(value)) ? parseFloat(value).toFixed(0) : value;
                                                setInfo({ index: "MonthlyIncome", value : (isNaN(value) ? "" : value) })
                                            }
                                        }
                                    }} 
                                    onKeyDown={(e) => {
                                        setCursorPosition(e.target.selectionStart)
                                    }}
                                    onKeyUp={(e) => {
                                        if(e.nativeEvent.key === "Backspace" ){
                                            e.target.setSelectionRange(cursor, cursor);
                                        }
                                    }}
                                    type="text" className="form-control" placeholder="$ 0.00" {...register("MonthlyIncome")} 
                                />
                            </OverlayTrigger>
                        </div>
                        {errors.MonthlyIncome && errors.MonthlyIncome.message.map((message) => (
                            <div className="error-message" key={"MonthlyIncome-" + message}> {message} </div>
                        ))}
                    </div>
                </div>
                { /* Income Calculator */}
                <div className="col-12">
                    <IncomeCalculator clearMonthlyIncomeError={clearMonthlyIncomeError}/>
                </div>
                { /* Source Income */}
                <div className="col-12 mt-3">
                    <div className="form-group">
                        <label htmlFor="IncomeSource">Primary Source of Monthly Income</label>
                        <div className="input-container">
                            <select className="form-control" onInput={(e) => setInfo({ index: "IncomeSource", value: e.target.value })} {...register("IncomeSource")}>
                                <option value=""> Please select one of the following </option>
                                <option value="Employed Full-time">Employed Full-time</option>
                                <option value="Employed Part-time">Employed Part-time</option>
                                <option value="Self Employed">Self Employed</option>
                                <option value="Unemployed">Unemployed</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        {errors.IncomeSource && (
                            <div className="error-message" > This field is missing. Please select an option. </div>
                        )}
                    </div>  
                    <p>{ text.block1.income }</p>               
                </div>
                { /* Primary Phone */}
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="PrimaryPhone">Mobile Phone Number <small>(10-digits)</small></label>
                        <div className="input-container">
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip   id="phone-tooltip" >{Parser(text.block1.tooltipPhone)}</Tooltip>}>
                            <input 
                                value={masking(info.PrimaryPhone, "(XXX) XXX-XXXX")} 
                                onInput={(e) => {
                                    let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("-","").replaceAll("(","").replaceAll(")","") : e.nativeEvent.data;
                                    if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                                        setInfo({ index: "PrimaryPhone", value: unmasking(e, "(XXX) XXX-XXXX", info.PrimaryPhone) });
                                        setInfo({ index: "PrimaryPhoneSms", value: unmasking(e, "(XXX) XXX-XXXX", info.PrimaryPhoneSms) })
                                    }
                                }} 
                                onFocus={() => {ada.sendDYEvent({ name : 'PrimaryPhoneFocusEvent'}); adobe.event('form_checkpoint')}}
                                type="text" className="form-control" placeholder="(XXX) XXX-XXXX" {...register("PrimaryPhone")} 
                            />
                            </OverlayTrigger>
                        </div>
                        {errors.PrimaryPhone &&  (
                            <div className="error-message">This field is missing or invalid. Please fill it correctly.</div>
                        )}
                    </div>
                </div>
                { /* Secondary Phone */}
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="SecondaryPhone">Secondary phone number</label>
                        <div className="input-container">                                   
                            <input 
                                value={masking(info.SecondaryPhone, "(XXX) XXX-XXXX")} 
                                onInput={(e) => {
                                    let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("-","").replaceAll("(","").replaceAll(")","") : e.nativeEvent.data;
                                    if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                                        setInfo({ index: "SecondaryPhone", value: unmasking(e, "(XXX) XXX-XXXX", info.SecondaryPhone) })
                                    }
                                    
                                }} 
                                
                                type="text" className="form-control" placeholder="(XXX) XXX-XXXX" {...register("SecondaryPhone")} 
                            />                                  
                        </div>
                        {errors.SecondaryPhone &&  (
                            <div className="error-message">This field is missing or invalid. Please fill it correctly.</div>
                        )}
                    </div>
                </div>
                { /* Date of Birth */}
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="dob">Date of Birth(MM/DD/YYYY)  </label>
                        <div className="input-container">
                        <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip   id="age-tooltip" >{text.block1.tooltipAge}</Tooltip>}>
                            <input                                         
                                value={
                                    moment(info.dob,"YYYY-MM-DD",true).isValid() 
                                    ? 
                                    moment(info.dob,"YYYY-MM-DD",true).format("MM/DD/YYYY") 
                                    : 
                                    masking(info.dob,"XX/XX/XXXX")}                                         
                                onInput={(e) => {
                                    let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("/","") : e.nativeEvent.data;
                                    if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                                        setInfo({ 
                                        index: "dob", 
                                        value: 
                                            moment(e.target.value, "MM/DD/YYYY",true).isValid() 
                                            ? 
                                            moment(e.target.value,"MM/DD/YYYY",true).format("YYYY-MM-DD") 
                                            :
                                            unmasking(e,"XX/XX/XXXX",info.dob) 
                                        })
                                    }
                                    }
                                }
                                
                                type="text" className="form-control" placeholder="XX/XX/XXXX" {...register("dob")} />
                                </OverlayTrigger>
                        </div>

                        { errors.dob ? errors.dob && <span className='error-message'> Please enter a valid date of birth. You must be at least 18 years of age or older to apply.  </span> : '' }
                    </div>
                </div>     
            </div>
            { /* Spouse Form */}
            {(info.State === "WI") && (
                <SpouseForm />
            )}
            { /* Checking Debit account */}
            <div className={(info.State === "WI") ? "row mt-3" : "row"}>
                <div className="col-12">
                    <div>Do you have an active checking account or debit card?</div>
                    {errors.HasCheckingAccount && errors.HasCheckingAccount.message.map((message) => (
                        <div className="error-message" key={"HasCheckingAccount-" + message}> This field is missing. Please select an option. </div>
                    ))}
                </div>
                <div className="col-12">
                    <div className="radioGroup">
                        <div className="radio-btn">
                            <input type="radio" checked={info.HasCheckingAccount === "true"} value={"true"} {...register("HasCheckingAccount")} onClick={(e) => setInfo({ index: "HasCheckingAccount", value: e.target.value, })}/>
                            <label className="ml-2">Yes</label>
                        </div>
                        <div className="radio-btn">
                            <input type="radio" checked={info.HasCheckingAccount === "false"} value={"false"} {...register("HasCheckingAccount")} onClick={(e) => setInfo({ index: "HasCheckingAccount", value: e.target.value, })}/>
                            <label className="ml-2">No</ label>
                        </div>
                    </div>
                </div>
            </div>
            { /* Cash Intent */}
            {checkIfFieldIsActive("cashIntent") && (        
                <div className="row mt-3">
                    <div className="col-12">
                        <div>Do you intend to use this card for cash advances?</div>
                        {errors.cashIntent && errors.cashIntent.message.map((message) => (
                            <div className="error-message"><p>{message}</p> </div>
                        ))}
                    </div>
                    <div className="col-12">
                        <div className="radioGroup">
                            <div className="radio-btn">
                                <input type="radio" checked={info.cashIntent === "true"} value={"true"} {...register("cashIntent")} onClick={(e) => setInfo({ index: "cashIntent", value: e.target.value, })}/>
                                <label className="ml-2">Yes</label>
                            </div>
                            <div className="radio-btn">
                                <input type="radio" checked={info.cashIntent === "false"} value={"false"} {...register("cashIntent")} onClick={(e) => setInfo({ index: "cashIntent", value: e.target.value, })}/>
                                <label className="ml-2">No</label>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            { /* Additional Card */}  
            <div className="row mt-3">
                <div className="col-12">
                    <div> Would you like an additional card?</div>
                    {errors.hasAdditionalCardholder &&  (
                        <div className="error-message"><p className="pl-0 font12">This field is missing. Please select an option.</p></div>
                    )}
                </div>
                <div className="col-12">
                    <div className="radioGroup">
                        <div className="radio-btn">
                            <input type="radio" checked={info.hasAdditionalCardholder === "true"} value={"true"} {...register("hasAdditionalCardholder")} onClick={(e) => setInfo({ index: "hasAdditionalCardholder", value: e.target.value, })}/>
                            <label className="ml-2">Yes</label>
                        </div>
                        <div className="radio-btn">
                            <input type="radio" checked={info.hasAdditionalCardholder === "false"} value={"false"} {...register("hasAdditionalCardholder")} onClick={(e) => setInfo({ index: "hasAdditionalCardholder", value: e.target.value, })}/>
                            <label className="ml-2">No</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3" >
                {info.hasAdditionalCardholder === "true" && ( 
                    <>  
                        <div className="col-12">
                            <div className="smallerText">
                                {text.block1.disclaimerAdditionalCardholder}
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="additionalCardholderFirstName">First Name</label>
                                <div className="input-container">
                                    <input 
                                        maxLength={17}
                                        value={info.additionalCardholderFirstName} 
                                        onInput={(e) => {
                                            if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                                                if(/^[a-zA-Z- ]+$/.test(e.target.value) || e.target.value === ""){
                                                    if((e.target.value.split(" ").length - 1) < 2){
                                                        setInfo({ index: "additionalCardholderFirstName", value: e.target.value.trimLeft() })}
                                                    }
                                                }
                                            }
                                        } 
                                        type="text" className="form-control" placeholder="First Name" 
                                        {...register(
                                            "additionalCardholderFirstName"
                                        )} />
                                </div>                                       
                                {errors.additionalCardholderFirstName && errors.additionalCardholderFirstName.message.map((message) => (
                                    <div className="error-message" key={"additionalCardholderMI-" + message}> {message} </div>
                                ))}
                                
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="additionalCardholderMI">MI</label>
                                <div className="input-container">
                                    <input 
                                        maxLength={1}
                                        value={info.additionalCardholderMI} 
                                        onInput={(e) => {
                                            if (/[a-zA-Z]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward") {
                                                setInfo({ index: "additionalCardholderMI", value: e.target.value })}
                                            }
                                            
                                        } 
                                        type="text" className="form-control" placeholder="MI" {...register("additionalCardholderMI")} />
                                </div>
                                {errors.additionalCardholderMI && errors.additionalCardholderMI.message.map((message) => (
                                    <div className="error-message" key={"additionalCardholderMI-" + message}> {message} </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="additionalCardholderLastName">Last Name</label>
                                <div className="input-container">
                                    <input 
                                        maxLength={25}
                                        value={info.additionalCardholderLastName} 
                                        onInput={(e) => {
                                            if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                                                if(/^[a-zA-Z- ]+$/.test(e.target.value) || e.target.value === ""){
                                                    if((e.target.value.split(" ").length - 1) < 2){
                                                        setInfo({ index: "additionalCardholderLastName", value: e.target.value.trimLeft() })}
                                                    }
                                                }
                                            }
                                        } 
                                        type="text" className="form-control" placeholder="Last Name" {...register("additionalCardholderLastName")} />
                                </div>
                                {errors.additionalCardholderLastName && errors.additionalCardholderLastName.message.map((message) => (
                                    <div className="error-message" key={"additionalCardholderLastName-" + message}> Required field. Please enter only letters and hyphens. </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="additionalCardholderSuffix">Suffix</label>
                                <select className="form-control" id="additionalCardholderSuffix" {...register("additionalCardholderSuffix") }  onInput={(e) => setInfo({ index: "additionalCardholderSuffix", value: e.target.value })}>
                                    <option value=""></option>
                                    <option value="JR">JR</option>
                                    <option value="SR">SR</option>
                                    <option value="II">II</option>
                                    <option value="III">III</option>
                                    <option value="IV">IV</option>
                                </select>
                            </div>
                            {errors.additionalCardholderSuffix &&
                            errors.additionalCardholderSuffix.message.map((message) => (
                                <div className="error-message" key={"additionalCardholderSuffix-" + message}> {message} </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
            { /* Authorize Prequalification */}
            <div className="row">
                <div className="col-12">
                    <div className="form-check check-fields">
                        <div className="input-container">
                            <input 
                                checked={info.authPrequal === "1" ? true : false} 
                                value={"1"} 
                                onClick={
                                    (e) => {
                                        setInfo({ index: "authPrequal", value: info.authPrequal === "1" ? "0" : "1", });
                                        ada.sendDYEvent({ name : 'PreQualifyClickEvent'});
                                    }
                                } 
                                disabled={disableField} className="form-check-input" type="checkbox" id="authPrequal" {...register("authPrequal", {  shouldUnregister: true })} />
                            <label className="form-check-label" htmlFor="authPrequal">
                                I Authorize Pre-qualification.
                            </label>
                        </div>
                        { errors.authPrequal ? errors.authPrequal && <span className='error-message'> You need to agree before continue  </span> : '' }
                    </div>
                </div>
                { /* Email Opt-In */}
                <div className="col-12">
                    <div className="form-check check-fields">
                        <div className="input-container">
                            <input checked={info.emailOptIn}  value={"true"} onClick={(e) => setInfo({ index: "emailOptIn", value: info.emailOptIn === true ? false : true, })}  disabled={disableField} className="form-check-input" type="checkbox"  id="email-optin-bulldog" {...register("emailOptIn")} />
                            <label className="form-check-label" htmlFor="email-optin-bulldog">
                                I consent and understand that {Setting.brandSettings.BankName} and Continental Finance Company will use any information I provide 
                                to apply for a credit product to enable physical and digital communications from selected affiliated and 
                                non-affiliated marketing partners in compliance with
                                the <a rel="noopener noreferrer" href={Setting.productSettings.PrivacyUrl} target="_blank">Privacy Notice</a>. My consent 
                                is not required for approval of this application. If I receive any email communications from affiliated or non-affiliated 
                                marketing partners, I understand that I can unsubscribe at any time by clicking the unsubscribe link in that email message.
                            </label>
                        </div>
                    </div>
                </div>
                { /* Agreement */}
                <div className="col-12">
                    <div className="form-check check-fields">
                        <div className="input-container">
                            <input checked={info.eSignConsent === "1" ? true : false}  value={"1"} onClick={(e) => setInfo({ index: "eSignConsent", value: info.eSignConsent === "1" ? "0" : "1", })} disabled={disableField} className="form-check-input" type="checkbox"  id="optin-e-disclosure"  {...register("eSignConsent")} />
                            <label className="form-check-label" htmlFor="optin-e-disclosure">
                                By clicking this box you agree that you have read and consent to <a onClick={() => { utms.updateSessionReport("disclosureLink") }} rel="noopener noreferrer" href={Setting.productSettings.ESignUrl} target="_blank">electronic disclosures</a>.
                            </label>
                        </div>
                        { errors.eSignConsent ? errors.eSignConsent && <span className='error-message'> You need to agree before continue  </span> : '' }
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default UserDataFormVertical;
