import { createStore, action, thunk } from 'easy-peasy';
import siteSettingsModel from '../models/siteSettingsModel';
import applicantModel from '../models/applicantModel';
import applicationResultModel from '../models/applicationResultModel';
import reservationModel from '../models/ReservationModel';
import cliModel from '../models/cliModel';
import routingSettingsModel from '../models/routingSettingsModel';
import prequalText from '../models/prequalText';
import dmText from '../models/DmText';
import errorHandler from '../models/errorHandler';
import DeclinedText from '../models/DeclinedText';
import CrossSellText from '../models/CrossSellText';
import CFCText from '../models/CFCText';
import applicantResponse from '../models/applicantResponse';
import applicationResultOffer from '../models/applicationResultOfferModel';
import GBText from '../models/GBText';

export default createStore({

    //GLobal Features
    assetsLink:"",
    assetsImageLink:"",
    cfcLogo: process.env.REACT_APP_LOGO,

    //Model Definition
    siteSettingsModel:      siteSettingsModel,          //Setting for the app
    applicantModel:         applicantModel,             //Applicant Info
    applicationResultModel: applicationResultModel,     //Result Model
    reservationModel:       reservationModel,           //Reservastion Model
    cliModel:               cliModel,                   //Client Model
    routingSettingsModel:   routingSettingsModel,       //Routing Settings
    errorHandler :          errorHandler,               //Error handler Model
    applicantResponse:      applicantResponse,
    applicationResultOffer: applicationResultOffer,

    //text of the page
    prequalText:            prequalText,          //Setting for the app
    dmText:                 dmText,          //Setting for the app
    crossSellText:          CrossSellText,
    DeclinedText:           DeclinedText,
    CFCText :               CFCText,
    GBText :                GBText,

    //Global States
    submitLoaderShow:       false,
    displayBanner:          true,
    displayReservation:     true,
    displayStepone:         true,
    displayPrequalTerms:    false,
    displayResult:          false,
    cliUnverified:          true,
    displayHeaderTerms:     false,
    alResult :              false,
    paymentPixels :         false,
    initPixels :            false,
    termsPixels:            false,
    flinkPixels:            false,
    grandBankPixels:        false,
    timeout:                false,
    initCardColors:         false,
    invalidState:           false,

    //Mfa
    mfaSms:                 false,
    mfaemail:               false,
    deadEndMfaSms:          false,

    sessionTimeoutId:       null,
    
    //Actions
    setAssetsLink:          action((state, payload) => { state.assetsLink = payload }),
    setInvalidState:        action((state, payload) => { state.invalidState = payload }),
    setAssetsImageLink:     action((state, payload) => { state.assetsImageLink = payload }),
    setsubmitLoaderShow:    action((state, payload) => { state.submitLoaderShow = payload }),
    setDisplayBanner:       action((state, payload) => { state.displayBanner = payload }),
    setDisplayReservation:  action((state, payload) => { state.displayReservation = payload }),
    setDisplayStepone:      action((state, payload) => { state.displayStepone = payload }),
    setDisplayPrequalTerms: action((state, payload) => { state.displayPrequalTerms = payload }),
    setDisplayResult:       action((state, payload) => { state.displayResult = payload }),
    setCliUnverified:       action((state, payload) => { state.cliUnverified = payload }), //CLI
    setDisplayHeaderTerms:  action((state, payload) => { state.displayHeaderTerms = payload }),
    setAlResult:            action((state, payload) => { state.alResult = payload }),
    setPaymentPixels:       action((state, payload) => { state.paymentPixels = payload }),
    setInitPixels:          action((state, payload) => { state.initPixels = payload }),
    setFlinkPixels:         action((state, payload) => { state.flinkPixels = payload }),
    setGrandBankPixels:     action((state, payload) => { state.grandBankPixels = payload }),
    setTermsPixel:          action((state, payload) => { state.termsPixels = payload }),
    setTimeout:             action((state, payload) => { state.timeout = payload }),
    setInitCardColors:      action((state, payload) => { state.initCardColors = payload }),
    resetDisplays:          action((state, payload) => { 
        //state.displayStepone = true 
        //state.displayPrequalTerms = false 
        //state.displayResult = false 
    }),
    setMfaSms:              action((state, payload) => { state.mfaSms = payload }),
    setMfaEmail:            action((state, payload) => { state.mfaemail = payload }),
    setDeadEndMfaSms:       action((state, payload) => { state.deadEndMfaSms = payload }),
    setSessionTimeoutId:    action((state, payload) => { state.sessionTimeoutId = payload }),

    //Global thunks

    nextStep: thunk((actions, payload) => {

      

    }),

});
