import React, { useState, useEffect, useRef } from 'react';
import { useStoreState } from 'easy-peasy';
import { faPlay, faQuestionCircle, faExpand } from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PixelsService from '../../../../services/pixels';
import AxiosWrapper from '../../../../services/axios';

function GrandBankEntryPoint2() {
    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);
    const GBTooltip = useStoreState(state => state.GBText.tooltip)
    const PixelService = new PixelsService();
    const setting = useStoreState(state => state.siteSettingsModel);
    const client = new AxiosWrapper();
    const grandBankVideo = "https://images" + ((process.env.REACT_APP_CFC_APPLICANT_BUILDENV !== "PRODUCTION") ? "test" : "") +
    ".continentalfinance.net/applicationweb/grandbank/G360_Credit_Builder_Video.mp4";

    useEffect(() => {
        document.body.style.backgroundColor = "#fff";
    }, []);

    function clickRedirect() {
        let id = applicationResult.ref_number;
        client.post("update-gb-redirect-status", { "interstitialPage": false, "ApplicantId": id, "isRedirected": true });
    };

    const GrandBankImg = {
        backgroundImage: "url('/Grand-Bank-Art2.png')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top left",
        width: "100%",
        minHeight: "650px"
    };

    const GrandBankImgMobile = {
        backgroundImage: "url('/Grand-Bank-Art2-mobile.png')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right",
        width: "100%"
    };

    const videoRefMobile = useRef(null);
    const videoRefDesktop = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const handlePlayPause = (ref) => {
        const video = ref.current;
        if (isPlaying) {
          video.pause();
        } else {
          video.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleProgressChange = (e, ref) => {
        const video = ref.current;
        const manualTime = e.target.value;
        video.currentTime = manualTime;
        setProgress(manualTime);
    };

    const handleFullscreen = (ref) => {
        const video = ref.current;
        if (video.requestFullscreen) {
          video.requestFullscreen();
        } else if (video.webkitRequestFullscreen) {
          video.webkitRequestFullscreen();
        } else if (video.msRequestFullscreen) {
          video.msRequestFullscreen();
        }
    };
    
    useEffect(() => {
        const updateProgress = (ref) => {
            setProgress(ref.current.currentTime);
        };
        const mobileVideo = videoRefMobile.current;
        const desktopVideo = videoRefDesktop.current;
    
        const onTimeUpdateMobile = () => updateProgress(videoRefMobile);
        const onTimeUpdateDesktop = () => updateProgress(videoRefDesktop);
    
        mobileVideo.addEventListener("timeupdate", onTimeUpdateMobile);
        desktopVideo.addEventListener("timeupdate", onTimeUpdateDesktop);
    
        return () => {
          mobileVideo.removeEventListener("timeupdate", onTimeUpdateMobile);
          desktopVideo.removeEventListener("timeupdate", onTimeUpdateDesktop);
        };
    }, []);

    return (
        <div className="mb-3 baseOffer grandBrank p-0">
            <div className="row baseOffer grandBrank" style={(window.innerWidth >= 990) ? GrandBankImg : null}>
                <h4 className="GBTitle d-block d-lg-none">Boost your Credit Score with G360 Credit Builder.</h4>
                <div className="col-12 d-block d-lg-none d-flex flex-column justify-content-center ml-2" style={(window.innerWidth < 990) ? GrandBankImgMobile : null}>
                    <h4 className="subTitle1">Up To</h4>
                    <h4 className="subTitle2">+60&nbsp;</h4>
                    <div className="d-flex">
                    <h4 className="subTitle3">Points</h4>
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="age-tooltip"><span dangerouslySetInnerHTML={{ __html: GBTooltip.score60 }} /></Tooltip>}>
                            <span className='GBQuestionMark' style={{marginTop: "20px"}}><FontAwesomeIcon color='#385d79' size='lg' icon={faQuestionCircle} /></span>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className="col-12 d-flex justify-content-end d-block d-lg-none">
                    <img src="/logo-with-TM.png" alt="grandBankLogo"/>
                </div>
                <div className="col-lg-5"></div>
                <div className="col-xs-12 col-sm-12 col-lg-7">
                    <h4 className="GBTitle d-none d-lg-block">Boost your Credit Score with G360 Credit Builder.</h4>
                    <div className="d-flex flex-row w-100 subtitles">
                        <div className="col-4">
                            <h4 className="subTitle1" style={{marginBottom: 0}}>Up</h4>
                            <h4 className="subTitle1">To</h4>
                        </div>
                        <div className="col-8 d-flex flex-row align-items-center">
                            <h4 className="subTitle2">+60&nbsp;</h4>
                            <h4 className="subTitle3">Points</h4>
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="age-tooltip"><span dangerouslySetInnerHTML={{ __html: GBTooltip.score60 }} /></Tooltip>}>
                                <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='lg' icon={faQuestionCircle} /></span>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <ul className='p-3 mb-0'>
                        <li className='d-flex flex-row'>
                            <span className='mr-3'><FontAwesomeIcon color='#ed4d4d' size='sm' icon={faPlay} /></span>
                            <label className='GBtextList'>Apply for a G360 Credit Builder, part loan, part savings, to help you build credit. <strong>No Credit Score required!</strong>
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="age-tooltip"><span dangerouslySetInnerHTML={{ __html: GBTooltip.score60 }} /></Tooltip>}>
                                <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='sm' icon={faQuestionCircle} /></span>
                            </OverlayTrigger>
                            </label>
                        </li>
                        <li className='d-flex flex-row'>
                            <span className='mr-3'><FontAwesomeIcon color='#ed4d4d' size='sm' icon={faPlay} /></span>
                            <label className='GBtextList'><strong>Choose from a variety of payment plans </strong>to fit your budget.</label>
                        </li>
                        <li className='d-flex flex-row'>
                            <span className='mr-3'><FontAwesomeIcon color='#ed4d4d' size='sm' icon={faPlay} /></span>
                            <label className='GBtextList mr-3'><strong>Make on-time monthly payments </strong>that are reported to the three major credit bureaus to build payment history.
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="age-tooltip" >{GBTooltip.onTimePayments}</Tooltip>}>
                                <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='sm' icon={faQuestionCircle} /></span>
                            </OverlayTrigger>
                            </label>
                        </li>
                        <li className='d-flex flex-row'>
                            <span className='mr-3'><FontAwesomeIcon color='#ed4d4d' size='sm' icon={faPlay} /></span>
                            <label className='GBtextList'><strong>Unlike typical programs, you get cash back monthly </strong>to build savings!
                            <small style={{ fontSize: '0.75em', lineHeight: '0.9' }}> (Funds are not released up front. Funds are released after 60 days, two on-time payments, and a small deduction for fees and interest.)</small>
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="age-tooltip" >{GBTooltip.cashBack}</Tooltip>}>
                                <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='sm' icon={faQuestionCircle} /></span>
                            </OverlayTrigger>
                            </label>
                        </li>
                    </ul>
                </div>
                <div className='col-12 d-block d-lg-none' style={{ minHeight: "120px" }}>
                    <form action={applicationResult.GrandbankUrl} method="POST" target='_blank'>
                        <input type="hidden" value={applicationResult.GBAuthId} name="authid" />
                        <button type='submit' className='btn btn-round btn-block tempClass'
                            onClick={() => { PixelService.event("google", setting.mainSettings.Flow, "GoToGrandBankButtonClick"); clickRedirect() }}
                            style={{ backgroundColor: "#f04d4e", width: "auto", padding: "10%", margin: "auto", fontWeight: "bold" }}
                        >
                            Get Started!
                        </button>
                    </form>
                </div>
                <div className='col-12'>
                    <div className='row mb-1 rowMargin' style={{ fontSize: "9pt" }}>
                        <div className='col-5 d-none d-lg-block'>
                            <p className='text-left p-0 mb-0' style={{ fontSize: "9pt" }}>By clicking “Get Started” you authorize the sharing of your personal information with Grand Bank for Savings, FSB.</p>
                            <p className='text-left p-0' style={{ fontSize: "9pt" }}>Grand.bank is a trademark of Grand Bank for Savings, FSB. Member FDIC | Equal Housing Lender.</p>
                        </div>
                        <div className='col-7 d-none d-lg-block'>
                            <form action={applicationResult.GrandbankUrl} method="POST" target='_blank'>
                                <input type="hidden" value={applicationResult.GBAuthId} name="authid" />
                                <button type='submit' className='btn btn-round btn-block tempClass'
                                    onClick={() => { PixelService.event("google", setting.mainSettings.Flow, "GoToGrandBankButtonClick"); clickRedirect() }}
                                    style={{ backgroundColor: "#f04d4e", width: "auto", padding: "10%", margin: "auto", fontWeight: "bold" }}
                                >
                                    Get Started!
                                </button>
                            </form>
                        </div>
                        <div className='col-12 mb-1 pr-2 pl-2'>
                            <div className="video-container d-block d-lg-none" onClick={() => handlePlayPause(videoRefMobile)}>
                                <video ref={videoRefMobile} style={{ width: "60%", margin: "auto" }} className="d-block" muted poster="/gb_thumbnail.jpg">
                                    <source src={grandBankVideo} type="video/mp4" />
                                        Your browser does not support the video tag.
                                </video>
                                {!isPlaying && (
                                    <button className="play-button" style={{ color: 'black' }} onClick={() => handlePlayPause(videoRefMobile)}>
                                        <FontAwesomeIcon icon={faPlay} />
                                    </button>
                                )}
                                <button className="fullscreen-button" onClick={(e) => {e.stopPropagation(); handleFullscreen(videoRefMobile)}}>
                                    <FontAwesomeIcon icon={faExpand} />
                                </button>
                                <input type="range" min="0" max={videoRefMobile.current?.duration || 0} value={progress} onChange={(e) => handleProgressChange(e, videoRefMobile)} className="progress-bar"
                                    style={{ width: "60%", margin: "0 auto 2%" }}
                                />
                            </div>
                            <div className="video-container d-none d-lg-block" onClick={() => handlePlayPause(videoRefDesktop)}>
                                <video ref={videoRefDesktop} style={{ width: "50%", margin: "auto" }} className="d-block" muted poster="/gb_thumbnail.jpg">
                                    <source src={grandBankVideo} type="video/mp4" />
                                        Your browser does not support the video tag.
                                </video>
                                {!isPlaying && (
                                    <button className="play-button" style={{ color: 'black' }} onClick={() => handlePlayPause(videoRefDesktop)}>
                                        <FontAwesomeIcon icon={faPlay} />
                                    </button>
                                )}
                                <button className="fullscreen-button" onClick={(e) => {e.stopPropagation(); handleFullscreen(videoRefDesktop)}}>
                                    <FontAwesomeIcon icon={faExpand} />
                                </button>
                                <input type="range" min="0" max={videoRefDesktop.current?.duration || 0} value={progress} onChange={(e) => handleProgressChange(e, videoRefDesktop)} className="progress-bar"
                                    style={{ width: "50%", margin: "0 auto 2%" }}
                                />
                            </div>
                            <p className='text-left p-0 font-weight-bold d-block d-lg-none pl-1' style={{ fontSize: "9pt" }}>By clicking “Get Started” you authorize the sharing of your personal information with Grand Bank for Savings, FSB.</p>
                            <p className='text-left p-0 d-block d-lg-none pl-1' style={{ fontSize: "9pt" }}>Grand.bank is a trademark of Grand Bank for Savings, FSB. Member FDIC | Equal Housing Lender.</p>
                            <p className='text-left p-0 pl-1' style={{ fontSize: "9pt" }}>
                                Important Information About Procedures for Opening a New Account or Establishing a New Customer Relationship: The USA PATRIOT Act is a federal law that helps the government
                                fight the funding of terrorism and money laundering activities. Federal law requires all U.S. financial institutions to obtain, verify and record information that identifies each person who opens
                                an account or establishes a customer relationship with Grand Bank for Savings, FSB. What this means for you: When you open a bank account, we will ask you for your name, address, date
                                of birth, and other information that will allow us to reasonably identify you. We may also ask to see your driver's license or other identifying documents at any time.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GrandBankEntryPoint2;