import React, { useEffect } from "react";
import { useStoreState } from "easy-peasy";
import { useHistory } from "react-router-dom";
import AudioEyeSupport from "../../../../services/audioEyeSupport";
import AdobeScript from "../../../../services/adobeScript";

function PendFee() {
  const brandSettings = useStoreState((state) => state.siteSettingsModel.brandSettings);
  const applicant = useStoreState((state) => state.applicantModel.applicant);
  const applicationResult = useStoreState((state) => state.applicationResultModel.applicationResult);
  const history = useHistory();
  const ada = new AudioEyeSupport();
  const adobeAds = new AdobeScript()

  const handleNextStep = () => {
    ada.sendDYEvent({ name : 'PayFee'});
    adobeAds.event('PayFee')
    history.push("/payment");
  }

  useEffect(() =>  {
    ada.sendDYEvent({ name : 'ApprovedPendfee'});
    adobeAds.event('ApprovedPendfee')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    return () => {
        if (history.action === 'POP') {
            history.go(1);
        }
    };
  }, [history]);

  return (
    <div className="container pendfee-container">
      <div className="row">
        <div className="col-lg-12 congratulations">
          <h2>Congratulations!</h2>
          <h3>
            You are approved for the {brandSettings.ProductName.toUpperCase()} Mastercard<span style={{verticalAlign: "super", fontSize: "0.75em"}}>&reg;</span> Credit Card.
          </h3>
        </div>
      </div>
      <div className="row card-box">
        <div className="col-lg-6">
          <img src={brandSettings.CardImage} alt="" className="col-lg-12" />
        </div>
        <div className="col-lg-6 result-message">
          <div>
            <span className="credit-limit-number">
              ${applicationResult.credit_limit || "400"}
            </span>
            <span className="credit-limit-text">Credit Limit</span>
          </div>
          <div>
            <div className="attribute">Processing Fee: </div>
            {/* this needs to change when we include processinf fee in the backend response */}
            <div>$95.00</div>
          </div>
          <div>
            <div className="attribute"> Approved Applicant: </div>
            <div>
              {applicant.FirstName || "Test"} {applicant.LastName || "Test"}
            </div>
          </div>
          <div>
            <div className="attribute">Your Reference Number: </div>
            <div>{applicationResult.ref_number || "16212152"}</div>
          </div>
        </div>
      </div>
      <div className="instructions-text">
        <div className="font-weight-bold">
          {/* this needs to change to {applicationResult.security_deposit} when we include processinf fee in the backend response */}
          In order to activate and use your new credit card when it arrives, you
          will need to submit payment of your $95.00 processing fee. Your account can
          not be opened or used until this payment is complete.
        </div>
        <div>
          If you would prefer to pay this fee over the phone, please call us at {" "}
          <span className="mobile-link"><a href="tel:1-888-525-3038">1-888-525-3038</a></span>
          <span className="desktop-number">1-888-525-3038</span>.
        </div>

        <div>
          If you would prefer to make this payment online please select one of
          the payment options below.
        </div>

        <div>Thank you and congratulations!</div>
      </div>
      <div className="row">
        <button
          className="btn pay-processing-button"
          style={{ backgroundColor: brandSettings.Primary, width: "auto" }}
          onClick={handleNextStep}
        >
          Pay my processing fee
        </button>
      </div>
    </div>
  );
}

export default PendFee;
